@import "../../../styles/mcpColors.module.scss";

.delivery-address-section .key {
  padding: 10px;
}

.shipCandidate {
  padding: 10px;
  margin: 2px;
  background-color: #ffffff;
}

.shipCandidate:hover {
  background-color: $sky-base;
}

.shipCandidate:nth-child(odd) {

}

.shipCandidate:nth-child(even) {

}

.upperCase {
  text-transform: uppercase;
}

.shipCardTitle {

}

.shipCandidate .item-key {
  max-width: 60px;
  min-width: 60px;
}

.shipFormManageServices {
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: small;
}

.delivery-address-section .item-key {
  max-width: 100px;
  min-width: 60px;
}

.thinHr {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid $shale;
}

.shippingBreadcrumbs {
  clear: both;
}

.shippingBreadcrumbs > ul {
  display: inline-block;
}

.deliveryAddressBox {
  border: 1px solid $platinum;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.deliveryAddressBox:hover, .deliveryAddressBox.selected {
  background: $silver;
  cursor: pointer;
}


.shipmentBox {
  border: 1px solid $platinum;
  padding-left: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.previewNoImages {
  background-color: rgb(239, 243, 245);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

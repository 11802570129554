@import "src/styles/mcpColors.module.scss";

.todayWidget {
  // tile = 1 box inside of the widget
  $max-tile-with: 220px;

  font-size: 25px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($max-tile-with, 1fr));
  a {
    transition: all 0.2s ease;
    width: 100%;
    padding: 5px;
    border-radius: 2px;
    :first-child {
      font-size: 40px;
      color: $coal
    }
    :last-child {
      font-size: 20px;
      color: $link;
    }
    :last-child:hover {
      color: $link-hover;
    }
    &:hover {
      text-decoration: none;
      background-color: $silver;
    }
    &.lateItemsSubWidget {
      :last-child {
        font-size: 20px;
        color: $persimmon-base;
      }
    }
  }
  .openItemsSubWidget {
    background-color: $horizon;
    :hover {
      background-color: $silver;
    }
  }
  .lateItemsSubWidget {
    background-color: #FFF6EE;
    color: $persimmon-base;
    :hover {
      background-color: $silver;
    }
  }
}

#late-items {
  color: $persimmon-base;
}

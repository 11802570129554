// required by the cimpress css and components
@import './mcpColors.module.scss';
@import './hackDefaultCheckbox.module.scss';
@import './reportIssue.module.scss';

// Disable underline when hovering a link
a{
  &:hover {
    // needs important, otherwise it's overridden by the mcp styles
    text-decoration: none !important;
  }
}

// Fix the position of the gear icon in the navbar
div.fluid-collapse.navbar-fluid-collapse.navbar-right > ul > li.icon-link > a {
  padding: 13.5px 16px 13.5px 3px;
}

// Hack for latest icons to align them 'better'
.toolbox svg, .nav svg {
  top: 2px;
  right: 2px;
}

.diffItem {
  padding-bottom: 2px;
  display: block;
}

.noDiffCaption {
  color: $cobalt-base;
  white-space: nowrap !important;
}

.diffCaption {
  color: $persimmon-darkest;
  font-weight: bolder;
  white-space: nowrap !important;
}

.diffOldText {
  color: $cobalt-base;
  font-weight: bolder;
  text-decoration: line-through;
  white-space: nowrap !important;
}

.diffNewText {
  color: $persimmon-darkest;
  font-weight: bolder;
  white-space: nowrap !important;
}

.noWrap {

}

.inline{
  display: inline;
}

.imagePreviewContainer {
  width: 168px;
  height: 168px;
  float: left;
  background: $horizon;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  line-height: 160px;
}

.spacedLabel {
  margin-bottom: 1px;
  margin-left: 2px;
}

.clickableLabel {
  cursor: pointer;
}

.linkColor{
  color: $link
}

// react-cimpress-comments
.comment-indicator {
  display: inline-block;
  margin-left: 2px;
  font-size: 2em;
  line-height: 17px;
  vertical-align: middle;
}

// Fix text color for buttons (TODO: Fix this in shared styles...)
.btn.btn-danger.hover,
.btn.btn-danger:hover,
.btn.btn-warning:hover,
.btn.btn-warning:hover {
  color: white !important;
}

.btn > svg {
  top: 2px;
}

.dropdown {
  display: inline-block;
}

.nav-item-badge {
  position: absolute;
  top: -4px;
  right: 2%;
}

.nav-item-badge .badge {
  padding: 3px 10px;
  border-radius: 2px;
}

.overflow-modal .modal-body {
  overflow-y: visible;
}

.itemPreview {
  border: 1px solid $sky-darkest;
}

.itemPreview img {
  padding: 5px;
}

// Hack default carousel transition speed
.carousel-inner>.item {
  transition: transform .1s ease-in-out !important;
}

@import "src/styles/mcpColors.module.scss";

.detailsPage .reportOptions .btn,
.detailsPage .actionOptions .btn {
  margin-right: 0.4em;
  margin-bottom: 0.4em;
}

.item-row {
  display: flex;
  align-items: center;
}

.item-key {
  max-width: 200px;
  min-width: 180px;
  flex: 1 0 0;
  word-break: break-all;
}

.item-value {
  flex: 1.5;
  margin-left: 10px;
}

.actionOptions {
  display: flex;
  border-bottom: 1px solid $horizon;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.leftActions {
  flex-grow: 100;
}

.rightActions {
  text-align: right;
  flex-grow: 1;
}

.actionOptions button {
  margin-bottom: 3px;
  margin-right: 3px;
}

.itemDetailsCaptionTitle {
  font-size: 24px; //h1
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.itemDetailsCaptionTitle:after {
  clear: both;
  content: "";
  display: block;
}

.itemDetailsCaptionTitleName {
  float: left;
}

.itemDetailsCaptionTitleLabels {
  float: left;
}

.item-details-box {
  margin-bottom: 5px;
  padding: 10px;
  box-shadow: 0 0 2px $alloy;
  border-radius: 2px;
}

.item-details-box > h5 {
  font-size: 18px;
  color: $shale;
}

.item-details-box-no-line {
  border-bottom: 0;
  padding: 10px;
}

.item-details-box-comments {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 5px solid $alloy;
}


.delivery-detail-section
{
  text-decoration: none;
  background: $white;
  border: 1px solid $silver;
  display: block;
  padding: 1em;
  -moz-box-shadow: 0px 0px 10px rgba(33,33,33,1);
  -webkit-box-shadow: 0px 0px 10px rgba(33,33,33,.7);
  box-shadow: 0px 0px 10px rgba(33,33,33,.7);
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.delivery-detail-section-title {
  font-weight: bolder;
}

.shipment-address {
  border-top: 1px solid $silver;
}

.item-details-previews {
  max-width: 470px;
  padding: 5px;
  margin-left: 10px;
}

// hack
.carousel-navigation .carousel-indicators li {
  border-radius: 2px !important;
}

.carousel.slide {
  border-radius: 2px;
}

.shipment-plan-viewer-modal .modal-content .modal-body {
  overflow-y: auto !important;
}
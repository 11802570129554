@import './mcpColors.module.scss';

/* checkbox aspect */
[type="checkbox"]:not(:checked):before {
  content: '';
  position: absolute;
  width: 16px; height: 16px;
  border: 1px solid $cobalt-base;
  border-radius: 1px;
  background: #fff;
}

[type="checkbox"]:checked:before {
  content: '';
  position: absolute;
  width: 16px; height: 16px;
  border: 1px solid $dark-teal-base;
  border-radius: 1px;
  background: $dark-teal-base;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked):after,
[type="checkbox"]:checked:after {
  content: '\2713\0020';
  position: absolute;
  padding-left: 3px;
  font-size: 16px;
  line-height: 1;
  color: white;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked):after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked):before,
[type="checkbox"]:disabled:checked:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked:after {
  color: #999;
}
[type="checkbox"]:disabled {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus:before,
[type="checkbox"]:not(:checked):focus:before {
  border: 2px dotted blue;
}

/* hover style just for information */
[type="checkbox"]:hover:before {
  border: 1px solid $teal-base !important;
}


@import "mcpColors.module";

@keyframes wiggle {
  0% {
    padding: 10px;
  }
  94% {
    padding-right: 10px;
  }
  97% {
    padding-right: 20px;
  }
  100% {
    padding-right: 10px;
  }
}

.report-issue-sticker {
  background-color: white;
  animation: wiggle 4s infinite !important;
  animation-iteration-count: 2 !important;
}

.report-issue-sticker {
  animation: unset;
  width: 130px;
  text-align: center;
  color: $persimmon-darkest;
  background-color: #f0cac3;
  border: none !important;
  border-right: 0px;
}

.report-issue-sticker {
  z-index: 100;
  position: fixed;
  right: 0px;
  bottom: 50px;
  width: 130px;
  color: #bc2111;
  text-align: center;
  padding: 10px;
  background-color: #f0cac3;
  border-radius:15px 0 0 15px;
  cursor: pointer;
}

.report-issue-sticker:hover {
  border-color: $persimmon-darkest;
  background-color: $persimmon-base;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
@import "src/styles/mcpColors.module.scss";

.jobsheets .printButtons button {
  margin-right: 0.4em;
  margin-bottom: 1em;
}

.documentSection {
  width: fit-content;
  margin: auto;
}

.documentCard {
  background-color: $shale !important;
}

.documentSection .documentPreview {
  padding: 5px;

}

.horizontalRadios .checkbox {
  display: inline-block;
}

.react-pdf__Page {
  border: 1px solid $platinum;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black;
}

.react-pdf__Page canvas {
  // width: 100% !important;
  // height: auto !important;
}

.jobsheetRadioGroup {
  display: inline-block;
}

.jobsheet-item-list {
  padding: 2px;
  margin-bottom: 5px;
}

@import "../../styles/mcpColors.module";

.columns-modal .modal-body {
  overflow-y: auto !important;
}

.multi-select {
  display: block;
  width: 100% !important;
  padding-left: 10px;
  //min-height: 15em;
}

.multi-select.form-control {
  padding: 0 10px;
}

.multi-select > option {
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;
}

.multi-select > option:checked,
.multi-select > option:hover {
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;
}

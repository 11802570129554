@import "src/styles/mcpColors.module.scss";

.riw-card-header {
  font-weight: inherit;
}

.riw-inv-title {
  color: $shale;
}

.riw-inv {
  border-bottom-color: $platinum;
}

.riw-inv-value {
  strong {
    font-weight: inherit;
  }
}

@import "src/styles/mcpColors.module";

.lateColumn {
  background-color: #FFF6EE;
}

.lateColumn a, .lateColumn a:visited {
  color: $persimmon-base !important;
}

.futureColumn {
  background-color: #F6FFEE;
}

.futureColumn a, .futureColumn a:visited {
  color: $success-darkest !important;
}

.totalRowOrColumn {
  font-weight: bolder;
  background-color: $silver;
}

// Override MCP styles
.backlogWrapper .ReactTable>.rt-table .rt-tr>.rt-td:first-child {
  padding: 11px !important;
}

.backlogWrapper .checkbox {
  display: inline-block;
  margin-left: 20px;
}

@import "src/styles/mcpColors.module.scss";

.settingsContainer {

}

.modal-body {
  overflow-y: visible !important;
}

.settingsVersion {
  color: $alloy;
  font-style: italic;
}

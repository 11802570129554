@import "src/styles/mcpColors.module.scss";

$theZeroPointFourEmGap: 0.4em;
$actionBarHeight: 65px;

.itemsPage {
  background-color: #fff;
  padding: 1em;
  margin-bottom: $actionBarHeight;
}

.actionBar {
  //border-bottom: 1px solid $horizon;
  padding-bottom: 10px;
  //margin-bottom: 10px;
}

.actionBar button {
  margin-bottom: 3px;
  margin-right: 3px;
}

.statusLineWrapper {
  margin-bottom: $theZeroPointFourEmGap;
  line-height: 48px;
}

.dateRangePicker svg {
  margin-top: 10px;
}
.dateRangePicker {
  white-space: nowrap;
  > div {
    margin-right: $theZeroPointFourEmGap;
  }
  .rdt {
    width: unset; // Hack, it's currently fixed in css to 300px
  }
}

.advancedFilters .checkbox {
  display: inline-block;
  margin-right: 20px;
  margin-top: 5px;
}

.itemsListActions > button {
  margin-right: 3px;
}

.itemsListTable {
  padding-bottom: 180px;
}

.itemsListTable .rt-tr {
  cursor: pointer;
}

.flexContainer {
  display: flex;
}

.flexChild {
  flex-grow: 1;
}

.flexChildx3 {
  flex-grow: 3;
}
.flexChildx5 {
  flex-grow: 5;
}

.flexChildGrow {
  flex-grow: 1000;
}

.spacedCheckbox {
  margin-right: 10px;
}

.internalLink {
  color: $teal-darker;
}

.internalLink:hover {
  color: $info-base;
  cursor: pointer;
}

.spaced-tag .tag {
  margin-right: 2px;
  margin-bottom: 2px;
}

.rt-tbody .rt-tr:hover {
  background-color: #F5FCFE;
}

.dateRangePickerWrapWithClose>i {
  color: black;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 999999999;
  cursor: pointer;
}

.dateRangePickerWrapWithClose>i:hover {
  color: $teal-base;
}

.dateRangePickerWrapWithClose {
  position: relative;
  display: inline-block;
}
